import React, { useEffect, useState } from "react";
import app from "gatsby-plugin-firebase-v9.0";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../Global/Link";
import CardPerson from "../Cards/CardPerson";

const SectionForm = ({ postID }) => {
  const accountId = localStorage.getItem("guest");

  const [dataAll, setDataAll] = useState("");

  const [firebaseUID, setFirebaseUID] = useState(null);

  const [noData, setNoData] = useState(false);

  const [formSaved, setFormSaved] = useState(null);

  const [rsvpSaved, setRsvpSaved] = useState(null);

  const [editForm, setEditForm] = useState(false);

  const [rsvp, setRsvp] = useState(false);

  const YupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Sorry, that looks like an invalid email")
      .required("This field is required"),
    phone: Yup.string().min(2, "Too Short!").max(12, "Too Long!"),
    note: Yup.string().max(340, "Sorry, this message is too long!"),
  });

  function confirmRsvp() {
    const now = new Date().toLocaleString();
    const database = getDatabase(app);
    const detailsRef = ref(database, "people/" + accountId + "/rsvp/");
    set(detailsRef, now).then(() => {
      // Data saved successfully!
      setRsvpSaved(true);
    });
  }

  function updateDetails(values) {
    const database = getDatabase(app);
    const detailsRef = ref(database, "people/" + accountId + "/details/");

    set(detailsRef, {
      email: values.email || "",
      phone: values.phone || "",
      note: values.note || "",
      uid: firebaseUID || 0,
    })
      .then(() => {
        // Data saved successfully!
        setFormSaved(true);
        setEditForm(false);
      })
      .catch((error) => {
        // The write failed...
        setFormSaved(false);
      });
  }

  useEffect(() => {
    const database = getDatabase(app);
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        // Get all data
        setFirebaseUID(auth.currentUser.uid);
        const fbQuery = ref(database, "people/" + accountId);
        onValue(fbQuery, (snapshot) => {
          const data = snapshot.val();
          setDataAll(data);
          if (data) {
            // Check if there is a UID (meaning they have edited the form already. If not, show 'edit mode')
            if (!data.details.uid) {
              setEditForm(true);
            }
            // See if this visitor has RSVPd already. If they have then we need to kill the ability to edit the fields
            if (data.rsvp) {
              setRsvp(true);
            }
          }
          if (!data) {
            setNoData(true);
          }
        });
      })
      .catch((error) => {
        alert("There was an error getting the data");
      });
  }, [postID]);

  if (noData)
    return (
      <section className="py-5 brand-background-black">
        <div className="container text-center brand-text-white">
          <div className="row">
            <h3 className="brand-font brand-responsive">
              Sorry, we can't find your details
            </h3>
            <p>
              Don't worry though - drop us an email or text to let us know that
              your access code (<strong>{accountId}</strong>) isn't working as
              expected!
            </p>
            <p>
              <Link to="/contact/" button icon colour="pink">
                OK
              </Link>
            </p>
          </div>
        </div>
      </section>
    );

  if (!dataAll)
    return (
      <section className="py-5 brand-background-black brand-section-min-height">
        <div className="container text-center py-5 brand-text-white">
          <FontAwesomeIcon icon="spinner" size="3x" spin pulse />
        </div>
      </section>
    );

  return (
    <section className="py-5 brand-background-black">
      <div className="container">
        <div className="row mb-5 justify-content-center">
          <div className="col-12 col-md-8 col-lg-6 text-center brand-text-white">
            <p className="fs-3">
              We're so excited for you to join us on our special day,{" "}
              <strong>Saturday 20th May 2023!</strong>
            </p>
            <p>
              Please confirm your attendance below and let us know if you have
              any dietary requirements, so we can make sure that all bellies are
              full!
            </p>
            <p>
              If you're bringing the kids, please also let us know which type of
              meal they'd like - or if it's best for you to bring your own!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-4 col-lg-6 mb-lg-0">
            <div className="py-4 px-3 rounded brand-background-white h-100">
              <div className="d-flex flex-row justify-content-between mb-4">
                <h2 className="brand-font">Guests</h2>
                <strong>#{accountId}</strong>
              </div>

              <ol className="list-group list-group-flush border-top">
                {dataAll?.guests &&
                  dataAll.guests.map((item, index) => {
                    return <CardPerson item={item} index={index} rsvp={rsvp} />;
                  })}
              </ol>
              <p className="text-center pt-4">
                {rsvp ? (
                  <>
                    {rsvpSaved && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <p>
                          <strong>Your RSVP is confirmed!</strong>
                        </p>
                        <p>
                          <Link to="/lineup/">
                            Check out what's planned for the big day (or what
                            you're missing out on)!
                          </Link>
                        </p>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          role="button"
                          onClick={() => setRsvpSaved(null)}
                        />
                      </div>
                    )}
                    <p>
                      If you need to update your RSVP between now and the big
                      day, please email{" "}
                      <Link to="clairepittaway@hotmail.co.uk">Claire</Link>
                    </p>
                  </>
                ) : (
                  <Link button role="button" onClick={() => confirmRsvp()} icon>
                    Confirm RSVP
                  </Link>
                )}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="py-4 px-3 rounded brand-background-white h-100">
              <Formik
                initialValues={{
                  email: dataAll.details.email,
                  phone: dataAll.details.phone,
                  note: dataAll.details.note,
                }}
                validationSchema={YupSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    updateDetails(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form>
                    <h2 className="brand-font mb-4">Your details</h2>

                    {formSaved === null ? (
                      ""
                    ) : formSaved === true ? (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Great stuff!</strong> Your details have been
                        updated!
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          role="button"
                          onClick={() => setFormSaved(null)}
                        />
                      </div>
                    ) : (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Oh no!</strong> There was a problem saving your
                        details!
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          role="button"
                          onClick={() => setFormSaved(null)}
                        />
                      </div>
                    )}

                    <div className="row gy-4">
                      <div className="col-12 col-md-6">
                        <label for="formEmail" className="form-label small">
                          Email
                        </label>
                        {editForm === true ? (
                          <>
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email"
                              className="form-control form-control-lg"
                              id="formEmail"
                            />
                            {errors.email && touched.email ? (
                              <div className="text-danger">{errors.email}</div>
                            ) : null}
                          </>
                        ) : (
                          <div>{dataAll.details.email}</div>
                        )}
                      </div>

                      <div className="col-12 col-md-6">
                        <label for="formPhone" className="form-label small">
                          Phone
                        </label>

                        {editForm === true ? (
                          <>
                            <Field
                              name="phone"
                              type="text"
                              className="form-control form-control-lg"
                              id="formPhone"
                            />
                            {errors.phone && touched.phone ? (
                              <div className="text-danger">{errors.phone}</div>
                            ) : null}
                          </>
                        ) : (
                          <div>{dataAll.details.phone}</div>
                        )}
                      </div>

                      <div className="col-12">
                        <label for="formEmail" className="form-label small">
                          Notes
                        </label>

                        {editForm === true ? (
                          <>
                            <Field
                              name="note"
                              as="textarea"
                              className="form-control form-control-lg"
                              placeholder="Message"
                            />
                            {errors.note && touched.note ? (
                              <div className="text-danger">{errors.note}</div>
                            ) : null}
                          </>
                        ) : (
                          <div>{dataAll.details.note}</div>
                        )}
                      </div>

                      <div className="col-12 text-end">
                        {editForm === true ? (
                          <Link
                            button="real"
                            role="button"
                            disabled={isSubmitting}
                            icon
                          >
                            Save
                          </Link>
                        ) : (
                          <Link
                            button
                            role="button"
                            onClick={() => setEditForm(true)}
                            icon="pen"
                          >
                            Edit
                          </Link>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionForm;
