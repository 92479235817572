import React, { useEffect, useState } from "react";
import app from "gatsby-plugin-firebase-v9.0";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import Link from "../Global/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const YupSchema = Yup.object().shape({
  diet: Yup.string().max(340, "Sorry, this message is too long!"),
});

const CardPerson = (props) => {
  const { index, item, rsvp } = props;

  const accountId = localStorage.getItem("guest");

  const [firebaseUID, setFirebaseUID] = useState(null);

  const dropdownLanguage = {
    none: "Please select",
    yes: "I'll be there!",
    maybe: "I'm not sure",
    no: "Sorry, I can't make it",
  };

  const dropdownLanguageChild = {
    none: "Please select",
    yes: "I'll be there!",
    maybe: "I'm not sure",
    no: "I'll be left at home",
  };

  const dropdownLanguageFood = {
    kids: "Kids meal (hotdogs)",
    adult: "I'll eat with the adults (Mexican)",
    none: "No food required",
  };

  function updateGuest(key, field, value) {
    //    alert("Update " + field + " with " + value);

    const now = new Date().toLocaleString();

    const database = getDatabase(app);
    const dataRef = ref(
      database,
      "people/" + accountId + "/guests/" + key + "/" + field
    );
    set(dataRef, value).then(() => {
      // Data saved successfully!
      //      updateRsvp();
      setEditForm(false);
    });
  }

  const [editForm, setEditForm] = useState(false);

  return (
    <li
      className={`list-group-item d-flex flex-column justify-content-center align-items-center flex-md-row justify-content-md-between align-items-md-start py-3 ${
        item.status === "yes"
          ? "brand-guest-yes"
          : item.status === "maybe"
          ? "brand-guest-maybe"
          : item.status === "no" || item.status === "deffono"
          ? "brand-guest-no"
          : "brand-guest-none"
      }`}
      key={index}
    >
      <span
        className="rounded-circle ratio ratio-1x1 brand-circle-icon me-md-2"
        style={{ width: "50px", height: "50px" }}
      >
        <span className="w-100 h-100 d-flex flex-column justify-content-center align-items-center text-center">
          {item.status === "yes" ? (
            <FontAwesomeIcon icon="check" />
          ) : item.status === "no" ? (
            <FontAwesomeIcon icon="times" />
          ) : (
            <span className="fs-3 brand-font">{index + 1}</span>
          )}
        </span>
      </span>

      <div className="ms-md-2 me-md-auto text-center text-md-start mb-3 mb-md-0">
        <div className="fw-bold">{item.name}</div>
        <div>{item.type}</div>
        {item.type === "Child" && (
          <div className="py-2">
            {rsvp ? (
              <>
                {item.food === "kids"
                  ? dropdownLanguageFood.kids
                  : item.food === "adult"
                  ? dropdownLanguageFood.adult
                  : item.food === "none"
                  ? dropdownLanguageFood.none
                  : dropdownLanguageFood.kids}
              </>
            ) : (
              <div className="dropdown">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn dropdown-toggle mb-0 border"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    role="button"
                  >
                    {item.food === "kids"
                      ? dropdownLanguageFood.kids
                      : item.food === "adult"
                      ? dropdownLanguageFood.adult
                      : item.food === "none"
                      ? dropdownLanguageFood.none
                      : dropdownLanguageFood.kids}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        className="dropdown-item"
                        role="button"
                        onClick={() => updateGuest(index, "food", "kids")}
                      >
                        {dropdownLanguageFood.kids}
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        role="button"
                        onClick={() => updateGuest(index, "food", "adult")}
                      >
                        {dropdownLanguageFood.adult}
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        role="button"
                        onClick={() => updateGuest(index, "food", "none")}
                      >
                        {dropdownLanguageFood.none}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}

        <Formik
          initialValues={{
            diet: item?.diet,
          }}
          validationSchema={YupSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              updateGuest(index, "diet", values.diet);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div>
                {editForm === true ? (
                  <>
                    <Field
                      name="diet"
                      as="textarea"
                      className="form-control form-control-lg"
                      placeholder="Dietary requirements"
                    />
                    {errors.diet && touched.diet ? (
                      <div className="text-danger">{errors.diet}</div>
                    ) : null}
                    <div className="py-4">
                      <Link
                        button
                        icon="times"
                        onClick={() => setEditForm(false)}
                        className="me-2"
                      >
                        Cancel
                      </Link>
                      <Link button="real" role="button" icon>
                        Save
                      </Link>
                    </div>
                  </>
                ) : (
                  <p className="small">
                    <em>{item.diet ? item.diet : "No dietary requirements"}</em>{" "}
                    {rsvp === false && (
                      <span
                        className="underline"
                        onClick={() => setEditForm(true)}
                      >
                        <u>Edit</u>
                      </span>
                    )}
                  </p>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {rsvp ? (
        <div>
          {item.status === "yes" ? (
            <strong>Attending</strong>
          ) : item.status === "no" ? (
            <strong>Not attending</strong>
          ) : (
            <strong>Not attending</strong>
          )}
        </div>
      ) : (
        <div className="dropdown">
          <div className="btn-group">
            <button
              type="button"
              className="btn dropdown-toggle mb-0 border"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              role="button"
            >
              {item.status === "yes" ? (
                <>
                  {item.type === "Child"
                    ? dropdownLanguageChild.yes
                    : dropdownLanguage.yes}
                </>
              ) : item.status === "maybe" ? (
                <>
                  {item.type === "Child"
                    ? dropdownLanguageChild.maybe
                    : dropdownLanguage.maybe}
                </>
              ) : item.status === "no" ? (
                <>
                  {item.type === "Child"
                    ? dropdownLanguageChild.no
                    : dropdownLanguage.no}
                </>
              ) : (
                dropdownLanguage.none
              )}
            </button>
            <ul className="dropdown-menu">
              <li>
                <span
                  className="dropdown-item"
                  role="button"
                  onClick={() => updateGuest(index, "status", "yes")}
                >
                  {item.type === "Child"
                    ? dropdownLanguageChild.yes
                    : dropdownLanguage.yes}
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  role="button"
                  onClick={() => updateGuest(index, "status", "no")}
                >
                  {item.type === "Child"
                    ? dropdownLanguageChild.no
                    : dropdownLanguage.no}
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </li>
  );
};

export default CardPerson;
