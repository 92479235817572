import React from "react";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import SectionForm from "../components/Sections/SectionForm";

// Markup
const RsvpPage = () => {
  const openGraph = {
    type: "website",
    title: "RSVP | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="RSVP" colour="pink" />
      <SectionForm />
    </Layout>
  );
};

export default RsvpPage;
